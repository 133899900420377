import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../components/Layout'
import { SeoData } from '../../types/Seo'
import { t } from '../../i18n'
import LandingFurgoneta from '../../../specific/static/landing-furgoneta'
import route from '../../utils/route'
import styles from '../landing.module.scss'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.furgoneta'),
        },
      ]}>
      <LandingFurgoneta styles={styles} data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingFurgonetaQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    imagenes_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/imagenes/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/imagenes/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/imagenes/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/imagenes/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/banners/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/banners/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/banners/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/furgoneta/banners/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-carrinha.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-para-carrinha.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_pt_desktop: allS3Asset(
      filter: { Key: { glob: "pneus/carrinhas-branca-motor-pneus.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_pt_mobile: allS3Asset(
      filter: { Key: { glob: "pneus/carrinhas-branca-pneus.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
