import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingFurgonetaPt = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneus para Carrinha" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
        A escolha de bons pneus é crucial para os profissionais do transporte rodoviário. Bons pneus para carrinha podem poupar muito dinheiro a uma empresa no final do ano e ter um impacto direto na qualidade de condução e segurança do transportador ao volante.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>
          Qual é o diferencial oferecido pelos pneus para carrinha?
          </h2>
          <p>
          Os pneus para carrinha diferem dos pneus para automóveis de passageiros em dois aspetos: são reforçados para serem mais resistentes e têm um índice de carga superior.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
          O que significa os pneus para carrinha serem reforçados?
          </h2>
          <p>
          Ao contrário dos pneus para automóvel, os pneus para carrinha tendem a ser utilizados mais intensamente no tráfego urbano. Por terem de suportar cargas de peso maiores, se mantivessem a estrutura de um pneu normal de automóvel, estes pneus sofreriam mais furos e rebentamentos. Para evitar isto, os pneus para carrinha estão equipados com proteções laterais e uma estrutura reforçada das lonas.
            <br />
            Por este motivo, as marcas de pneus fabricam modelos exclusivos para utilização em carrinhas. Mais pesados, devido ao reforço estrutural, mas mais resistentes à rodagem, a riscos e altas pressões. Alguns exemplos de modelos conhecidos para carrinhas são a Michelin Agilis CrossClimate, Pirelli Carrier ou Goodyear EfficientGrip.
            O índice de carga de um pneu para carrinha está entre o indicador 100 e 115, enquanto para um automóvel situa-se entre 80 e 100. Pelo contrário, o código de velocidade em carrinhas é inferior, estando os seus pneus preparados para atingir velocidades máximas de entre 175 km/h e 185 km/h.
          </p>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-furgoneta'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: 1,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'furgoneta',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer
          showMarcas={false}
          origin={route('landing-furgoneta')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingFurgoneta = ({ ...props }: Props) => (
  <LandingFurgonetaPt {...props} />
)

export default LandingFurgoneta
